import { Link, useLoaderData } from '@remix-run/react';
import { ValidatedForm } from 'remix-validated-form';
import {
  json,
  type ActionFunction,
  type LoaderFunctionArgs,
} from '@remix-run/node';
import { authenticator } from '~/lib/server/auth.server';

import { getSession } from '~/lib/server/session.server';
import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';
import { DefaultErrorBoundary, Input, SubmitButton } from '@cardo/ui';
import { returnToCookie } from '~/lib/server/cookies.server';

export const ErrorBoundary = DefaultErrorBoundary;

export const action: ActionFunction = async ({ request }) => {
  const returnTo = await returnToCookie.parse(request.headers.get('Cookie'));

  return await authenticator.authenticate('user-pass', request, {
    successRedirect: returnTo ?? '/',
    failureRedirect: '/login',
  });
};

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const returnTo = url.searchParams.get('returnTo');

  let headers = new Headers();
  if (returnTo) {
    headers.append('Set-Cookie', await returnToCookie.serialize(returnTo));
  }

  await authenticator.isAuthenticated(request, {
    successRedirect: returnTo ?? '/',
  });
  const session = await getSession(request.headers.get('cookie'));
  const error = session.get(authenticator.sessionErrorKey);
  return json({ error }, { headers });
}

export const validator = withZod(
  z.object({
    username: z.string().min(1, {
      message: 'Username is required',
    }),
    password: z.string().min(1, { message: 'Password is required' }),
  })
);

export default function Login() {
  const { error } = useLoaderData<typeof loader>();

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <ValidatedForm
        validator={validator}
        method="post"
        className="flex w-full flex-col space-y-3 sm:w-fit"
        id="login-form"
      >
        <div className="flex flex-col">
          <Input
            name="username"
            label="Username:"
            type="text"
            formId="login-form"
          />
          <Input
            name="password"
            label="Password:"
            type="password"
            formId="login-form"
          />
        </div>
        <SubmitButton
          label="Log in"
          labelSubmitting="Logging in..."
          formId="login-form"
        />
        {error && <p className="mt-3 text-red-600">{error.message}</p>}
      </ValidatedForm>
      <Link to="/forgotpassword" className="mt-8 w-full text-center text-xs">
        Forgot password?
      </Link>
    </div>
  );
}
